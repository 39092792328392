/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.service-task-block {
  margin-bottom: 16px;
  margin-left: calc(20% / 2);
}
.mobile .service-task-block {
  margin-left: 0;
}
.service_repair-form {
  padding: 16px 32px;
}
.service_repair-firmware-message-pane {
  font-size: 1.1em;
  margin-top: 4px;
}
.service_repair-firmware-message {
  color: #faad14;
}
